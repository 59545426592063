<div class="app-container ta_app_container">
  <div class="globals ta_app_globals">
    <oir-overlay class="ta_app_overlay"></oir-overlay>
    <oir-spinner class="ta_app_spinner"></oir-spinner>
    <oir-notifications-tray class="ta_app_notificationTray"></oir-notifications-tray>
    <oir-toast-notifications class="ta_app_toastNotifications"></oir-toast-notifications>
    <oir-navigation class="oirtNavigationPanel ta_app_navigation"></oir-navigation>
    <oir-module-info class="ta_app_info"></oir-module-info>
  </div>
  <div id="body" class="body ta_app_body">
    <router-outlet></router-outlet>
  </div>
</div>