import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigurationService,
  IAppSettings
} from 'proceduralsystem-clientcomponents';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface AppConfig extends IAppSettings {
  defaultLang: string;
  WeekLongPdfUrl: string; 
  RoleId: string;
  UserId: string;
  UserRoleMemberId: string;
  ReturnUrl: string;
  AADGroupBillsAdmin: string;
  CustomHeader: string;
  IsSeniorClerkOrAP: string;
  VersionNumber: string;
  CKEditor5LicenseKey: string;
}

const cookieDays = 30;

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private localSettings: AppConfig;
  private config$: Observable<AppConfig> | null = null;
  private http: HttpClient;
  private readonly values$ = new BehaviorSubject(null);

  constructor(
    private httpHandler: HttpBackend,
    private config: ConfigurationService<AppConfig>
  ) {
    this.http = new HttpClient(httpHandler);
  }


  get<T extends keyof AppConfig>(prop: T): Observable<AppConfig[T]> {
    if (!this.config$) {
      this.config$ = this.fetchConfig();
    }

    return this.config$.pipe(pluck(prop));
  }

  getValue<T extends keyof AppConfig>(prop: T): AppConfig[T] {
    const config = this.values$.getValue();

    return config ? config[prop] : null;
  }

  set<T extends keyof AppConfig>(prop: T, value: string): void {
    const config = this.values$.getValue();
    config[prop] = value;

    this.config$ = of(config);
  }

  setCookie(property: string, value: string, days = cookieDays): void {
    this.config.setCookie(property, value, days);
  }

  private fetchConfig(): Observable<AppConfig> {
    return this.fetchLocalSettings().pipe(
      switchMap(v => this.fetchBESettings()),
      map(v => Object.assign(v, this.localSettings)),
      tap(v => this.values$.next(v))
    );
  }

  public fetchLocalSettings(): Observable<AppConfig> {
    return this.http.get<AppConfig>('../../app.config.json').pipe(
      tap(v => (this.localSettings = v)),
      tap(v => this.values$.next(v)),
      switchMap(() => this.fetchConfig()),
      takeUntil(this.values$.pipe(filter(v => !!v)))
    );
  }

  private fetchBESettings(): Observable<IAppSettings> {
    return this.config.getAll(this.localSettings);
  }
}
