import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './services/app-config.service';
import { OpNavigationService } from './services/op-navigation.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { MonitoringService } from 'proceduralsystem-clientcomponents';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit {
  constructor(
    private readonly configuration: AppConfigService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly opNavigationService: OpNavigationService,
    private readonly monitoringService: MonitoringService
  ) {}

  ngOnInit(): void {
    this.listenRouterEvents();
    forkJoin([
      this.configuration.get('UserName'),
      this.configuration.get('AppInsightsKey')
    ])
      .pipe(take(1))
      .subscribe(([userName, appInsightsKey]) => {
        this.monitoringService.init(appInsightsKey, userName);
        this.configuration.setCookie('lang', 'en');
      });
    this.opNavigationService.initNavigation();
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.opNavigationService.clearExpandedChildNodes(event.title);
        this.titleService.setTitle(event.title);
      });
  }
}
