export enum MainNavigation {
  QUALITY_MANAGER = 'quality-manager',
  DAIL_BUSINESS = 'dail-business',
  ADD_NEW_ITEM = 'add-new-item',
  ARCHIVE = 'archive'
}

export enum GlobalValidation {
  MAX_100 = 100,
  MAX_500 = 500,
  MAX_2000 = 2000,
  MAX_3000 = 3000,
  MAX_5000 = 5000,
  MAX_10000 = 10000
}

export enum GenericTabsEnum {
  Bills = 0,
  Motions,
  Statements,
  MoneyMessages,
  OtherItems
}

export enum OtherItemTypes {
  EstimateMoneyMessages = 1,
  MessagesFromTheSeanad,
  SpecialAddress,
  CommitteeReportPrivateBills,
  CommitteeReportStandingOrders,
  ReportFromExaminerOfPrivateBills,
  TheReadingByTheClerk,
  ReportOfTheClerk,
  CertificateOfAttorney,
  CustomItem
}

export enum FormType {
  MOTION,
  AMENDMENT,
  BILL
}

export enum ItemStatus {
  New = 1,
  PedingUpdate,
  PendingReview,
  Reviewed,
  Removed = 7
}

export enum FieldType {
  Multiselect = 'multiselect',
  Datepicker = 'datepicker',
  Singleselect = 'singleSelect',
  Textarea = 'textarea',
  Input = 'input',
  ReadOnly = 'read-only',
  Typeahead = 'typeahead',
  Date = 'date',
  Button = 'button',
  FormattedText = 'formattedText',
  Toggle = 'toggle',
  TextEditor = 'textEditor'
}

export enum FieldSize {
  HALF = 1,
  FULL,
  TRANSLATE_BUTTON
}

export enum BusinessItemTypes {
  Bill = 1,
  Motion,
  Statement,
  BillMoneyMessage,
  Other
}

export enum FieldApprovalStatus {
  Pending = 0,
  Approved,
  Previous
}

export enum CommonNumbers {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
  ELEVEN,
  TWELVE,
  THERTEEN
}

export enum DebounceTime {
  ONE_HUNDRED = 100,
  TWO_HUNDRED = 200
}

export const ItalicBoldEditorToolbar = {
  toolbar: [
    {
        name: 'basicstyles',
        items : [ 'Bold', 'Italic' ]
    }
  ],
};

export enum RuntimeStatus {
  Running = 'Running',
  Completed = 'Completed',
  Pending = 'Pending'
}
